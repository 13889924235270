<template>
  <div class='surveys-table'>
    <base-table
      v-if='smsSentLog && smsSentLogMeta'
      :columns='columns'
      :data='smsSentLog'
      :total-items='smsSentLogMeta.total'
      :start-items='smsSentLogMeta.skip + 1'
      :max-items='smsSentLogMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-user='row'>
        {{ row.username ? row.username : '-' }}
      </template>

      <template v-slot:body-status='row'>
        {{ row.item.status ? formatStatus(row.item.status) : '-' }}
      </template>

      <template v-slot:body-createdAt='row'>
        {{ formatDate(row.item.createdAt) }}
      </template>

      <template v-slot:body-functionality='row'>
        {{ row.item.functionality ? row.item.functionality : '-' }}
      </template>

      <template v-slot:body-statusHistory='row'>
        <b-tooltip :target='`statusHistory${row.item._id}`' triggers='hover' placement='left'>
          <view-json-file
            v-if='row.item.statusHistory'
            :file='row.item.statusHistory'
          ></view-json-file>

          <p v-else>-</p>
        </b-tooltip>

        <p :id='`statusHistory${row.item._id}`' class='whatTheSearchReturned'>
          Ver histórico
        </p>
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import ViewJsonFile from '@/components/ViewJsonFile.vue'

export default {
  name: 'SMSSentLogTable',
  props: {
    smsSentLog: { type: Array, require: true },
    smsSentLogMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'username', value: 'Usuário' },
        { key: 'createdAt', value: 'Data' },
        { key: 'phone', value: 'Telefone' },
        { key: 'status', value: 'Status' },
        { key: 'message', value: 'Mensagem' },
        { key: 'securityCode', value: 'Código' },
        { key: 'service', value: 'Integração' },
        { key: 'functionality', value: 'Solicitado quando?' },
        { key: 'statusHistory', value: 'Histórico' }
      ]
    }
  },
  components: {
    ViewJsonFile
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    formatStatus(status) {
      switch (status) {
        case 'queued' : {
          return 'Código está em fila para ser enviado'
        }
        case 'sent' : {
          return 'Código foi enviado, mas o usuário ainda não recebeu'
        }
        case 'delivered' : {
          return 'Código foi enviado e usuário recebeu'
        }
        case 'failed' : {
          return 'Falha ao enviar o código'
        }
        case 'undelivered' : {
          return 'Código foi enviado, mas não foi entregue'
        }
        case 'aws-sent' : {
          return 'Enviado via aws, nao temos feedback do status'
        }
        case 'SENT' : {
          return 'Código foi enviado'
        }
        case 'RECEIVED' : {
          return 'Código foi recebido'
        }
        case 'READ' : {
          return 'Código foi lido'
        }
      }
    },
    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.smsSentLogMeta.limit)
      }
      this.$emit('change-activecampaignTagSent', params)
    }
  }
}
</script>

<style>
.tooltip-inner {
  background: #FFFFFF;
  text-align: left;
  max-width: 600px !important;
  width: 600px !important;
}
</style>
<style lang='scss' scoped>

.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.whatTheSearchReturned {
  cursor: pointer;
  text-decoration: underline;
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
