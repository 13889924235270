<template>
  <div class="answers-survey-download">
    <span class="question">
      <strong>Qual a nota você dá para esta solução inovadora?</strong>
      {{body.nps}}
    </span>
  </div>
</template>

<script>
export default {
  name: "AnswersDownload",
  props: {
    body: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
.answers-survey-download {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
  }
}
</style>
