<template>
  <div class="surveys-table">
    <base-table
      v-if="surveys && surveysMeta"
      :columns="columns"
      :data="surveys"
      :total-items="surveysMeta.total"
      :start-items="surveysMeta.skip + 1"
      :max-items="surveysMeta.limit"
      @set-page="handleChangeSurveys"
    >
      <template v-slot:body-user="row">
        {{ row.item.user.username ? row.item.user.username : '' }}
      </template>

      <template v-slot:body-type="row">
        <p v-if="row.item.type === 'satisfaction-surveys'">
          Pesquisa de Satisfação
        </p>
        <p v-else-if="row.item.type === 'login'">
          Login
        </p>

        <p v-else-if="row.item.type === 'user-profile'">
          Perfil do Usuário
        </p>
        <p v-else-if="row.item.type === 'recommendation-surveys'">
          Pesquisa de recomendação
        </p>
        <p v-else-if="row.item.type === 'plan-cancellation'">
          Cancelamento do plano
        </p>
        <p v-else>
          Download
        </p>
      </template>
      <template v-slot:body-user="row">
        {{ row.item.user && row.item.user.username ? row.item.user.username : '-' }}
      </template>

      <template v-slot:body-date="row">
          {{ row.item.date ? formatDate(row.item.date) : '' }}
      </template>
      <template v-slot:body-actions="row">
        <i
          class="fas fa-search-plus fa-lg"
          @click="$emit('get-survey', row.index)"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'

export default {
  name: 'SurveysTable',
  props: {
    surveys: { type: Array, require: true },
    surveysMeta: { type: Object, require: true }
  },
  data(){
    return {
      columns: [
        { key: 'user', value: 'Usuário' },
        { key: 'type', value: 'Tipo' },
        { key: 'date', value: 'Data' },
        { key: 'actions', value: 'Ações' },
      ],
    }
  },
  methods: {
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    handleChangeSurveys(page){
      const params = {
        skip: ((page - 1) * this.surveysMeta.limit)
      }
      this.$emit('change-surveys', params)
    }
  },
}
</script>

<style lang="scss" scoped>
.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
