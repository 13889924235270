<template>
  <div class='surveys-table'>
    <base-table
      v-if='activecampaignTagSent && activecampaignTagSentMeta'
      :columns='columns'
      :data='activecampaignTagSent'
      :total-items='activecampaignTagSentMeta.total'
      :start-items='activecampaignTagSentMeta.skip + 1'
      :max-items='activecampaignTagSentMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-user='row'>
        {{ row.item && row.item.user && row.item.user.username ? row.item.user.username : '-' }}
      </template>

      <template v-slot:body-createdAt='row'>
        {{ formatDate(row.item.createdAt) }}
      </template>

      <template v-slot:body-userProfile='row'>
        <b-tooltip :target='`userProfile${row.item._id}`' triggers='hover' placement='auto'>
          <view-json-file
            v-if='row.item.userProfile'
            :file='row.item.userProfile'
          ></view-json-file>

          <p v-else>-</p>
        </b-tooltip>

        <p :id='`userProfile${row.item._id}`' class='whatTheSearchReturned'>
          Ver Perfil do usuário
        </p>
      </template>

      <template v-slot:body-informationSentCreateContact='row'>
          <b-tooltip :target="`informationSentCreateContact${row.item._id}`" triggers='hover' placement='auto'>
            <view-json-file
              v-if='row.item.informationSentCreateContact'
              :file='row.item.informationSentCreateContact'
            ></view-json-file>

            <p v-else>-</p>
          </b-tooltip>

          <p :id="`informationSentCreateContact${row.item._id}`" class='whatTheSearchReturned'>
            Ver informaçãoes enviadas para criar contato
          </p>
        </template>

        <template v-slot:body-successSendInfoCreateContact='row'>
          <p>
            {{ row.item.successSendInfoCreateContact ? 'Sim' : 'Não' }}
          </p>
        </template>

        <template v-slot:body-informationReceivedCcreateContact='row'>
          <b-tooltip :target="`informationReceivedCcreateContact${row.item._id}`" triggers='hover' placement='auto'>
            <view-json-file
              v-if='row.item.informationReceivedCcreateContact'
              :file='row.item.informationReceivedCcreateContact'
            ></view-json-file>

            <p v-else>-</p>
          </b-tooltip>

          <p :id="`informationReceivedCcreateContact${row.item._id}`" class='whatTheSearchReturned'>
            Ver informaçãoes recebidas ao criar contato
          </p>
        </template>

        <template v-slot:body-informationSentCreateContactTag='row'>
          <b-tooltip :target="`informationSentCreateContactTag${row.item._id}`" triggers='hover' placement='auto'>
            <view-json-file
              v-if='row.item.informationSentCreateContactTag'
              :file='row.item.informationSentCreateContactTag'
            ></view-json-file>

            <p v-else>-</p>
          </b-tooltip>

          <p :id="`informationSentCreateContactTag${row.item._id}`" class='whatTheSearchReturned'>
            Ver informaçãoes enviadas ao vincular tag ao  usuário
          </p>
        </template>

        <template v-slot:body-successSendInfoCreateContactTag='row'>
          <p>
            {{ row.item.successSendInfoCreateContactTag ? 'Sim' : 'Não' }}
          </p>
        </template>

        <template v-slot:body-informationReceivedCreateContactTag='row'>
          <b-tooltip :target="`informationReceivedCreateContactTag${row.item._id}`" triggers='hover' placement='auto'>
            <view-json-file
              v-if='row.item.informationReceivedCreateContactTag'
              :file='row.item.informationReceivedCreateContactTag'
            ></view-json-file>

            <p v-else>-</p>
          </b-tooltip>

          <p :id="`informationReceivedCreateContactTag${row.item._id}`" class='whatTheSearchReturned'>
            Ver informaçãoes recebidas  ao vincular tag ao  usuário
          </p>
        </template>

    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import { mapActions } from 'vuex'
import ViewJsonFile from '@/components/ActivecampaignTagSent/ViewJsonFile'

export default {
  name: 'ActivecampaignTagSentTable',
  props: {
    activecampaignTagSent: { type: Array, require: true },
    activecampaignTagSentMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'user', value: 'Usuário' },
        { key: 'tag', value: 'Tag' },
        { key: 'createdAt', value: 'Data' },
        { key: 'userProfile', value: 'Perfil do usuário' },
        { key: 'informationSentCreateContact', value: 'Informaçãoes enviadas para criar contato' },
        { key: 'successSendInfoCreateContact', value: 'Usuário foi criado?' },
        { key: 'informationReceivedCcreateContact', value: 'Informaçãoes recebidas ao criar contato' },
        { key: 'informationSentCreateContactTag', value: 'Informaçãoes enviadas ao vincular tag ao  usuário' },
        { key: 'successSendInfoCreateContactTag', value: 'Usuário foi vinculado a tag?' },
        { key: 'informationReceivedCreateContactTag', value: 'Informaçãoes recebidas  ao vincular tag ao  usuário' }
      ]
    }
  },
  components: {
    ViewJsonFile
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },

    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.activecampaignTagSentMeta.limit)
      }
      this.$emit('change-activecampaignTagSent', params)
    }
  }
}
</script>

<style>
.tooltip-inner {
  background: #FFFFFF;
  text-align: left;
  max-width: 600px !important;
  width: 600px !important;
}
</style>
<style lang='scss' scoped>

.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.whatTheSearchReturned {
  cursor: pointer;
  text-decoration: underline;
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
