<template>
  <div class='answers-survey-download'>
    <span class='question'>
      <strong>Qual foi o motivo do cancelamento do plano?</strong>
      {{ body.cancellationReason }}
    </span>

  </div>
</template>

<script>
export default {
  name: 'PlanCancellation',
  props: {
    body: { type: Object, required: true }
  },
  data() {
    return {}
  }
}
</script>

<style lang='scss' scoped>
.answers-survey-download {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
  }
}
</style>
