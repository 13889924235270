<template>
  <div class='surveys-view mt-3'>
    <b-card no-body>
      <b-tabs v-model="tabIndex" pills card>
        <b-tab @click="setBreadcrumbTabsName('Pesquisas')" no-body title='Pesquisas'>
          <div v-if='tabIndex === 0' class='m-2'>
            <survey />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Frequência de uso')" no-body title='Frequência de uso'>
          <div v-if='tabIndex === 1' class='m-2'>
            <frequency-use />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Agentes adicionados')" no-body title='Agentes adicionados'>
          <div v-if='tabIndex === 2' class='m-2'>
            <chemical-agents-researched />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Assinaturas')" no-body title='Assinaturas'>
          <div v-if='tabIndex === 3' class='m-2'>
            <logs-assinatura-planos />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Tags enviadas')" no-body title='Tags enviadas'>
          <div v-if='tabIndex === 4' class='m-2'>
            <activecampaign-tag-sent/>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('SMS enviados')" no-body title='SMS enviados'>
          <div v-if='tabIndex === 5' class='m-2'>
            <s-m-s-sent-log/>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Pesquisa de agente')" no-body title='Pesquisa de agente'>
          <div v-if='tabIndex === 6' class='m-2'>
            <SearchAgents/>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('UTM de cadastro')" no-body title='UTM cadastro'>
          <div v-if='tabIndex === 7' class='m-2'>
            <UserUTMTable/>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('UTM de compra')" no-body title='UTM compra'>
          <div v-if='tabIndex === 8' class='m-2'>
            <PlansUTMTable/>
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Empresas')" no-body title='Empresas'>
          <div v-if='tabIndex === 9' class='m-2'>
            <CompaniesTable/>
          </div>
        </b-tab>
        <b-tab @click="setBreadcrumbTabsName('FDS/FISPQ dos produtos')" no-body title='FDS/FISPQ dos produtos'>
          <div v-if='tabIndex === 10' class='m-2'>
            <AttachmentTable/>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import Survey from '@/components/Surveys/Survey'
import FrequencyUse from '@/components/FrequencyUse/FrequencyUse'
import LogsAssinaturaPlanos from '@/views/LogsAssinaturaPlanos'
import ChemicalAgentsResearched from '@/components/ChemicalAgentsResearched/ChemicalAgentsResearched'
import SearchAgents from '@/components/SearchAgents/SearchAgents'
import ActivecampaignTagSent from '@/components/ActivecampaignTagSent/ActivecampaignTagSent'
import SMSSentLog from '@/components/SMSSentLog/SMSSentLog'
import { mapActions } from 'vuex'
import UserUTMTable from '@/components/UTM/UserUTMTable.vue'
import PlansUTMTable from '@/components/UTM/PlansUTMTable.vue'
import CompaniesTable from '@/components/ManageCompanies/CompaniesTable.vue'
import AttachmentTable from '@/components/ProductLegalConsultation/AttachmentTable.vue'

export default {
  name: 'Surveys',
  data() {
    return {
      tabIndex: 0,
      breadcrumbTabsName: 'Pesquisas'
    }
  },
  components: {
    PlansUTMTable,
    AttachmentTable,
    UserUTMTable,
    Survey,
    FrequencyUse,
    ChemicalAgentsResearched,
    ActivecampaignTagSent,
    LogsAssinaturaPlanos,
    SMSSentLog,
    SearchAgents,
    CompaniesTable,
  },
  computed: {},
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),

    setBreadcrumbTabsName(breadcrumbTabsName){
      this.breadcrumbTabsName = breadcrumbTabsName;
      const breadcrumb = {
        page: 'Relatórios',
        subPage: {
          name: 'Relatórios',
          active: false
        },
        tabs: [
          {
            name: this.breadcrumbTabsName,
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    }
  },
  created() {
    this.setBreadcrumbTabsName('Pesquias')
  },
  watch: {}
}
</script>

<style lang='scss' scoped>

</style>
