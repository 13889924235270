<template>
  <div class="answers-survey-login">
    <span class="question">
      <strong>
        Você se sente mais seguro trabalhando com o HO Fácil Web?
      </strong>
      {{safetyOptions[body.safety]}}
    </span>
    <span class="question">
      <strong>
        Você estima ter aumentado sua produtividade em quantos por cento?
      </strong>
      {{body.productivity}}
    </span>
    <span class="question">
      <strong>
        De 0 a 10, qual a chance de você indicar o Ho Fácil Web para um colega de profissão?
      </strong>
      {{body.recommendation}}
    </span>
    <span class="question">
      <strong>
        Como você se sentiria se não pudesse mais utilizar o HO Fácil Web?
      </strong>
      <span v-html="feelingOptions[body.feelings]"/>
    </span>
  </div>
</template>

<script>
export default {
  name: "AnswersDownload",
  props: {
    body: { type: Object, required: true }
  },
  data(){
    return {
      safetyOptions: {
        false: 'Não',
        true: 'Sim'
      },
      feelingOptions: {
        0: '&#128555;',
        2.5: '&#128543;',
        5: '&#128528;',
        7.5: '&#128522;',
        10: '&#128515;',
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.answers-survey-login {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
  }
}
</style>
