<template>
  <div>
    <json-viewer
      v-if='file'
      :value='file'
      :expand-depth=5
      :expanded='true'
      copyable
      boxed
      sort>
    </json-viewer>
  </div>
</template>

<script>

export default {
  name: 'ViewJsonFile',
  props: {
    file: { default: null },
  },
  data() {
    return {
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    isValid() {
      if (!this.startDate || !this.endDate) {
        return false
      }

      return true
    },
    async handleChangeItems() {
      const params = {
        startDate: new Date(this.startDate).toISOString().split('T')[0],
        endDate: new Date(this.endDate).toISOString().split('T')[0]
      }

      this.$emit('change-log-api', params)
    }
  }
}
</script>

<style lang='scss' scoped>
header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .btn {
    height: 48px;
  }

  .inputs {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-weight: bold;
    }
  }
}
</style>
