<template>
  <base-modal class="modal-see-survey" :isOpened="isOpen" @close="closeModal">
    <template v-slot:header>
      <h3>DADOS DA PESQUISA</h3>
    </template>
    <template v-slot:content>
      <div v-if="survey" class="infos">
        <h3>Informações</h3>
        <span v-if="survey.date">
          <strong>Data: </strong>
          {{survey.date ? formatDate(survey.date) : '-'}}
        </span>
        <span v-if="survey.type">
          <strong>Tipo: </strong>
          <span v-if="survey.type === 'satisfaction-surveys'">
            Pesquisa de Satisfação
          </span>
          <span v-else-if="survey.type === 'user-profile'">
            Perfil do Usuário
          </span>
          <span v-else-if="survey.type === 'plan-cancellation'">
            Cancelamento do plano
          </span>
          <span v-else-if="survey.type === 'login'">
            Login
          </span>
          <span v-else-if="survey.type === 'recommendation-surveys'">
            Pesquisa de recomendação
          </span>
          <span v-else>
            Download
          </span>
        </span>
        <span v-if="survey.user">
          <strong>Usuário: </strong>
          {{survey.user.username}}
        </span>
      </div>
      <div class="answers">
        <h3>Respostas</h3>
        <answers-download
          v-if="survey.type === 'download' && survey.body"
          :body="survey.body"
        />
        <answers-login
          v-else-if="survey.type === 'login' && survey.body"
          :body="survey.body"
        />
        <answers-satisfaction
          v-else-if="survey.type === 'satisfaction-surveys' && survey.body"
          :body="survey.body"
        />
        <user-profile
          v-else-if="survey.type === 'user-profile' && survey.body"
          :body="survey.body"
        />
        <plan-cancellation
          v-else-if="survey.type === 'plan-cancellation' && survey.body"
          :body="survey.body"
        />
        <recommendation
          v-else-if="survey.type === 'recommendation-surveys' && survey.body"
          :body="survey.body"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import format from 'date-fns/esm/format/index';
import { mapActions, mapGetters } from 'vuex';
import AnswersDownload from './Answers/Download';
import AnswersLogin from './Answers/Login';
import AnswersSatisfaction from './Answers/Satisfaction';
import UserProfile from './Answers/UserProfile';
import PlanCancellation from './Answers/PlanCancellation';
import Recommendation from './Answers/Recommendation';

export default {
  name: 'ModalSeeSurvey',
  props: {
    isOpen: { type: Boolean, default: false },
  },
  components: {
    AnswersDownload,
    AnswersLogin,
    AnswersSatisfaction,
    UserProfile,
    PlanCancellation,
    Recommendation,
  },
  computed: {
    ...mapGetters('surveys', {
      survey: 'getSurvey',
    }),
  },
  methods: {
    ...mapActions('surveys', {
      handleCreateSurvey: 'handleCreateSurvey',
      resetSurvey: 'resetSurvey',
    }),
    closeModal() {
      this.$emit('close');
    },
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy')
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}
</style>
