<template>
  <div>
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.filter'
          placeholder='Pesquisar por email'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>
      </div>

      <s-m-s-sent-log-table
        :smsSentLog='getSmsSent'
        :smsSentLogMeta='getSmsSentMeta'
        @change-activecampaignTagSent='listAllActivecampaignTagSent'
      />

      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import SMSSentLogTable from '@/components/SMSSentLog/Table'
import { mapActions, mapGetters } from 'vuex'
import XLSX from 'xlsx'

export default {
  name: 'SMSSentLog',
  components: {
    SMSSentLogTable
  },
  computed: {
    ...mapGetters('smsSentLog', {
      getSmsSent: 'getSmsSent',
      getSmsSentMeta: 'getSmsSentMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
  },
  data() {
    return {
      loading: false,
      filter: {
        startDate: '',
        endDate: '',
        filter: ''
      },
    }
  },
  async mounted() {
    await this.listAllActivecampaignTagSent()
  },
  methods: {
    ...mapActions('smsSentLog', {
      handleSmsSentMeta: 'handleSmsSentMeta',
      resetSmsSentMetaMeta: 'resetSmsSentMetaMeta',
    }),
    async applyFilters() {
      await this.listAllActivecampaignTagSent(this.filter)
    },
    async resetFilters() {
      this.filter.startDate = ''
      this.filter.endDate = ''
      this.filter.filter = ''
      await this.resetSmsSentMetaMeta()
      await this.listAllActivecampaignTagSent()
    },
    async listAllActivecampaignTagSent(payload) {
      this.loading = true;
      const params = {
        ...this.getSmsSentMeta,
        ...payload
      }
      await this.handleSmsSentMeta(params)
      this.loading = false;
    },
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}
.loading-spinner{
  margin-top: 250px;
}
</style>
