export const returnDescription = (value) => {
  const action = actions.find((x) => x.value === value)
  return action ? action.description : '-'
}

export const returnActionValue = (action) => {
  return actions.find((x) => x.action === action).value
}

const actions = [
  {
    action: 'LOGIN',
    value: 1,
    description: 'Fez Login'
  },
  {
    action: 'LOGOUT',
    value: 2,
    description: 'Fez Logout'
  },
  {
    action: 'IMPORTOU_LISTA_AGENTES_CONSULTA_LEGAL',
    value: 3,
    description: 'Importou lista de agentes na consulta legal'
  },
  {
    action: 'ACESSOU_PAGINA_CONSULTA_LEGAL',
    value: 4,
    description: 'Acessou a página da Consulta Legal'
  },
  {
    action: 'ADICIONOU_AGENTE_LISTA_AGENTES_USUARIO_CONSULTA_LEGAL',
    value: 5,
    description: 'Adicionou agente na lista de agentes do usuário na consulta legal'
  },
  {
    action: 'APAGOU_AGENTE_LISTA_AGENTES_USUARIO_CONSULTA_LEGAL',
    value: 6,
    description: 'Apagou agente na lista de agentes do usuário na consulta legal'
  },
  {
    action: 'GEROU_RELATORIO_LAUDO_INSALUBRIDADE',
    value: 7,
    description: 'Gerou relatório de Laudo de Insalubridade'
  },
  {
    action: 'GEROU_RELATORIO_ACGIH',
    value: 8,
    description: 'Gerou relatório de ACGIH'
  },
  {
    action: 'GEROU_RELATORIO_LTCAT',
    value: 9,
    description: 'Gerou relatório de LTCAT'
  },
  {
    action: 'ACESSOU_PAGINA_LINKS_PARA_DOWNLOAD',
    value: 10,
    description: 'Acessou a página dos Link de relatórios '
  },
  {
    action: 'DOWNLOAD_RELATORIO_LAUDO_INSALUBRIDADE',
    value: 11,
    description: 'Download do relatório de Laudo de Insalubridade'
  },
  {
    action: 'DOWNLOAD_RELATORIO_ACGIH',
    value: 12,
    description: 'Download do relatório de ACGIH'
  },
  {
    action: 'DOWNLOAD_RELATORIO_LTCAT',
    value: 13,
    description: 'Download do relatório de LTCAT'
  },
  {
    action: 'DOWNLOAD_INVENTARIO_QUIMICO',
    value: 14,
    description: 'Download do inventário químico'
  },
  {
    action: 'LISTAGEM_EMPRESA_INVENTARIO_QUIMICO',
    value: 15,
    description: 'Listagem de empresa no inventário químico'
  },
  {
    action: 'CADASTROU_EMPRESA_INVENTARIO_QUIMICO',
    value: 16,
    description: 'Cadastrou empresa no inventário químico'
  },
  {
    action: 'ATUALIZOU_EMPRESA_INVENTARIO_QUIMICO',
    value: 17,
    description: 'Atualizou empresa no inventário químico'
  },
  {
    action: 'EXCLUIU_EMPRESA_INVENTARIO_QUIMICO',
    value: 18,
    description: 'Excluiu empresa no inventário químico'
  },
  {
    action: 'LISTAGEM_UNIDADE_INVENTARIO_QUIMICO',
    value: 19,
    description: 'Listagem de empresa no inventário químico'
  },
  {
    action: 'CADASTROU_UNIDADE_INVENTARIO_QUIMICO',
    value: 20,
    description: 'Cadastrou unidade no inventário químico'
  },
  {
    action: 'ATUALIZOU_UNIDADE_INVENTARIO_QUIMICO',
    value: 21,
    description: 'Atualizou unidade no inventário químico'
  },
  {
    action: 'EXCLUIU_UNIDADE_INVENTARIO_QUIMICO',
    value: 22,
    description: 'Excluiu unidade no inventário químico'
  },
  {
    action: 'LISTAGEM_SETOR_INVENTARIO_QUIMICO',
    value: 23,
    description: 'Listagem de setor no inventário químico'
  },
  {
    action: 'CADASTROU_SETOR_INVENTARIO_QUIMICO',
    value: 24,
    description: 'Cadastrou setor no inventário químico'
  },
  {
    action: 'ATUALIZOU_SETOR_INVENTARIO_QUIMICO',
    value: 25,
    description: 'Atualizou setor no inventário químico'
  },
  {
    action: 'EXCLUIU_SETOR_INVENTARIO_QUIMICO',
    value: 26,
    description: 'Excluiu setor no inventário químico'
  },
  {
    action: 'LISTAGEM_CARGO_INVENTARIO_QUIMICO',
    value: 27,
    description: 'Listagem de cargo no inventário químico'
  },
  {
    action: 'CADASTROU_CARGO_INVENTARIO_QUIMICO',
    value: 28,
    description: 'Cadastrou cargo no inventário químico'
  },
  {
    action: 'ATUALIZOU_CARGO_INVENTARIO_QUIMICO',
    value: 29,
    description: 'Atualizou cargo no inventário químico'
  },
  {
    action: 'EXCLUIU_CARGO_INVENTARIO_QUIMICO',
    value: 30,
    description: 'Excluiu cargo no inventário químico'
  },
  {
    action: 'LISTAGEM_PRODUTO_INVENTARIO_QUIMICO',
    value: 31,
    description: 'Listagem de produto no inventário químico'
  },
  {
    action: 'CADASTROU_PRODUTO_INVENTARIO_QUIMICO',
    value: 32,
    description: 'Cadastrou produto no inventário químico'
  },
  {
    action: 'ATUALIZOU_PRODUTO_INVENTARIO_QUIMICO',
    value: 33,
    description: 'Atualizou produto no inventário químico'
  },
  {
    action: 'EXCLUIU_PRODUTO_INVENTARIO_QUIMICO',
    value: 34,
    description: 'Excluiu produto no inventário químico'
  },
  {
    action: 'LISTAGEM_CONDICOES_USO_INVENTARIO_QUIMICO',
    value: 35,
    description: 'Listagem de condições de uso no inventário químico'
  },
  {
    action: 'CADASTROU_CONDICOES_USO_INVENTARIO_QUIMICO',
    value: 36,
    description: 'Cadastrou condições de uso no inventário químico'
  },
  {
    action: 'ATUALIZOU_CONDICOES_USO_INVENTARIO_QUIMICO',
    value: 37,
    description: 'Atualizou condições de uso no inventário químico'
  },
  {
    action: 'IMPORTOU_PRODUTO_INVENTARIO_QUIMICO',
    value: 38,
    description: 'Importou produto no inventário químico'
  },
  {
    action: 'ADICIONOU_AGENTE_PRODUTO_INVENTARIO_QUIMICO',
    value: 39,
    description: 'Adicionou agente no produto do inventário químico'
  },
  {
    action: 'ATUALIZOU_PORCENTAGEM_AGENTE_PRODUTO_INVENTARIO_QUIMICO',
    value: 40,
    description: 'Atualizou a porcentagem do agente no produto do inventário químico'
  },
  {
    action: 'APAGOU_AGENTE_PRODUTO_INVENTARIO_QUIMICO',
    value: 41,
    description: 'Apagou agente no produto do inventário químico'
  },
  {
    action: 'VERIFICOU_HIERARQUIA_COMPLETA_INVENTARIO_QUIMICO',
    value: 42,
    description: 'Verificou se a hierarquia esta completa no inventário químico'
  },
  {
    action: 'GEROU_RELATORIO_INVENTARIO_QUIMICO',
    value: 43,
    description: 'Gerou relatório de inventário químico'
  },
  {
    action: 'CLICOU_ABA_SELECAO_DE_AGENTES',
    value: 44,
    description: 'Clicou na aba de seleção de agentes'
  },
  {
    action: 'CLICOU_ABA_LAUDO_DE_INSALUBRIDADE',
    value: 45,
    description: 'Clicou na aba de laudo de Insalubridade'
  },
  {
    action: 'CLICOU_ABA_ACGIH',
    value: 46,
    description: 'Clicou na aba ACGIH'
  },
  {
    action: 'CLICOU_ABA_LTCAT',
    value: 47,
    description: 'Clicou na aba LTCAT'
  },
  {
    action: 'EXPORTOU_EM_PDF',
    value: 48,
    description: 'Exportou em PDF'
  },
  {
    action: 'SELECIONOU_PLANO_ANUAL_ADERIR',
    value: 49,
    description: 'Selecionou o plano anual para aderir'
  },
  {
    action: 'SELECIONOU_PLANO_MENSAL_ADERIR',
    value: 50,
    description: 'Selecionou o plano mensal para aderir'
  },
  {
    action: 'SELECIONOU_PLANO_PROMOCIONAL_ADERIR',
    value: 51,
    description: 'Selecionou o plano promocional para aderir'
  },
  {
    action: 'PREENCHEU_DADOS_FATURAMENTO',
    value: 52,
    description: 'Preencheu os dados do faturamento'
  },
  {
    action: 'PREENCHEU_DADOS_PAGAMENTO',
    value: 53,
    description: 'Preencheu os dados do pagamento'
  },
  {
    action: 'FINALIZOU_COMPRA',
    value: 54,
    description: 'Finalizou compra'
  },
  {
    action: 'ACESSOU_PAGINA_INVENTARIO_QUIMICO',
    value: 55,
    description: 'Acessou a página do Inventário Químico '
  },
  {
    action: 'LOCALIZACAO_USUARIO',
    value: 56,
    description: 'Localização do usuário'
  },
  {
    action: 'ALTEROU_TELEFONE',
    value: 57,
    description: 'Alterou o telefone'
  },
  {
    action: 'CLICOU_ABA_AVALIACAO_QUANTITATIVA',
    value: 59,
    description: 'Clicou na aba Avaliação Quantitativa'
  },
  {
    action: 'GEROU_RELATORIO_AVALIACAO_QUANTITATIVA',
    value: 60,
    description: 'Gerou relatório de Avaliação Quantitativa'
  },
  {
    action: 'DOWNLOAD_RELATORIO_AVALIACAO_QUANTITATIVA',
    value: 61,
    description: 'Download do relatório de Avaliação Quantitativa'
  },
  {
    action: 'CLICOU_ABA_PCMSO',
    value: 62,
    description: 'Clicou na aba PCMSO'
  },
  {
    action: 'GEROU_RELATORIO_AVALIACAO_PCMSO',
    value: 63,
    description: 'Gerou relatório de PCMSO'
  },
  {
    action: 'DOWNLOAD_RELATORIO_PCMSO',
    value: 64,
    description: 'Download do relatório de PCMSO'
  },
  {
    action: 'PREENCHEU_PERGUNTA_EXPOSICAO_PERMANENTE',
    value: 65,
    description: 'Preencheu pergunta da exposição permanente'
  },
  {
    action: 'EDITOU_PERGUNTA_EXPOSICAO_PERMANENTE',
    value: 66,
    description: 'Editou pergunta da exposição permanente'
  },
  {
    action: 'PREENCHEU_PERGUNTA_PROTECAO_EFICAZ',
    value: 67,
    description: 'Preencheu pergunta da proteção eficaz'
  },
  {
    action: 'EDITOU_PERGUNTA_PROTECAO_EFICAZ',
    value: 68,
    description: 'Editou pergunta da proteção eficaz'
  },
  {
    action: 'CLICOU_ABA_GRO',
    value: 69,
    description: 'Clicou na aba GRO'
  },
]
