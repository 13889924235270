<template>
  <section>
    <section>
      <strong>Em uma escala de 0 a 10, o quanto você recomendaria a HOFácil para outras pessoas?</strong>
    </section>

    <section
      class='scale mt-2'
      :style='`background: ${scales.find((x) => x.scale === body.scale).color}`'
    >
      <span class='label'>{{ scales.find((x) => x.scale === body.scale).scale }}</span>
    </section>
  </section>
</template>

<script>
export default {
  name: 'Recommendation',
  props: {
    body: { type: Object, required: true }
  },
  data() {
    return {
      scales: [
        {
          scale: 0,
          color: '#B72224;'
        },
        {
          scale: 1,
          color: '#D52029;'
        },
        {
          scale: 2,
          color: '#E95223;'
        },
        {
          scale: 3,
          color: '#EA6F22;'
        },
        {
          scale: 4,
          color: '#F6A726;'
        },
        {
          scale: 5,
          color: '#FDC729;'
        },
        {
          scale: 6,
          color: '#EBDB0A;'
        },
        {
          scale: 7,
          color: '#E5E044;'
        },
        {
          scale: 8,
          color: '#C2D234;'
        },
        {
          scale: 9,
          color: '#AEC93C;'
        },
        {
          scale: 10,
          color: '#66B44E;'
        }
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
.scale {
  width: 50px;
  height: 50px;
  padding: 9.5px 14.5px;
  border-radius: 4px;
  opacity: 0.8;

  .label {
    text-align: center;
    color: #FFFFFF !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
}
</style>
