<template>
  <div>
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <b-modal
        header-bg-variant='info'
        header-text-variant='light'
        hide-footer
        id='select_format'
        title='Selecione o formato do arquivo.'
        ref='select_format'
      >
        <b-form-group
          class='ml-2 objectiveAnalysisSelected'
          v-slot='{ ariaDescribedby }'>
          <b-form-radio-group
            id='checkbox-group-2'
            class='pr-2'
            v-model='type'
            :aria-describedby='ariaDescribedby'
            name='flavour-2'
            stacked
          >
            <b-form-radio
              class='item py-2 text'
              value='frequencyOfUsexlsx'
            >
              <strong>
                Excel(.xlsx)
              </strong>

              <img width='20' class='mb-2' height='20' src='@/assets/images/File-excel.svg' alt='' />
            </b-form-radio>

            <b-form-radio
              class='item py-2 text'
              value='frequencyOfUsecsv'
            >
              <strong>
                Csv(.csv)
              </strong>
              <img width='20' class='mb-2' height='20' src='@/assets/images/file-csv.svg' alt='' />
            </b-form-radio>

            <b-form-radio
              class='item py-2 text'
              value='frequencyOfUsejson'
            >
              <strong>
                Json(.json)
              </strong>
              <img width='22' class='mb-2' height='22' src='@/assets/images/json-file.svg' alt='' />
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-row class='d-flex w-100 mx-1 justify-content-between'>
          <base-button
            block
            @click="generateReport()"
            class='w-100  btn_export_modal d-flex justify-content-center align-items-center'
          >
            <span class='d-flex justify-content-center align-items-center'>
              <img src='@/assets/images/export.svg' class='mr-2'  alt=''/>
              Exportar
            </span>
          </base-button>
        </b-row>
      </b-modal>

      <b-modal
        ref='alert'
        scrollable
        hide-footer
        title='Atenção'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <section class='modal-font-family'>
          <p>
            A impressão do arquivo em Excel esta sendo processada,
            clique no botão abaixo para acessar a página de downloads.
          </p>

          <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button class='btn_export_modal'>
              <span class="label">Acessar</span>
            </base-button>
          </router-link>
        </section>
      </b-modal>

      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>

      <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.filter'
          placeholder='Pesquisar por email ou ação'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>

        <b-overlay
          :show='loadingExport'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='justify-content-center'
        >
          <base-button
            class='btn-export mr-1 mt-1'
            @click='selectReportFormat()'
          >
            <section v-if='loadingExport'>
              <span>Exportando...</span>
            </section>

            <section
              v-else
            >
              <b-icon
                font-scale='1.1'
                class='pa-1 mr-1'
                icon='printer'
              >
              </b-icon>
              <span>Clique aqui para exportar</span>
            </section>
          </base-button>
        </b-overlay>
      </div>

      <logs-frequency-of-use-table
        :logsFrequencyOfUse='logsFrequencyOfUse'
        :logsFrequencyOfUseMeta='logsFrequencyOfUseMeta'
        @change-logsFrequencyOfUse='handleLogsFrequencyOfUse'
      />

      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import LogsFrequencyOfUseTable from '@/components/FrequencyUse/Table'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import XLSX from 'xlsx'
export default {
  name: 'FrequencyUse',
  components: {
    LogsFrequencyOfUseTable
  },
  computed: {
    ...mapGetters('userActionLogs', {
      logsFrequencyOfUse: 'getlogsFrequencyOfUse',
      logsFrequencyOfUseMeta: 'getlogsFrequencyOfUseMeta',
    }),
    user() {
      return this.$store.getters['user/current']
    },
    exportSurveys() {
      const { startDate, endDate, filter } = this.filter
      return `${process.env.VUE_APP_BASE_URL}/userActionLogs/exportXLSX/${startDate || 'not_selected'}/${endDate || 'not_selected'}/${filter || 'not_selected'}`
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      loadingExport: false,
      type: null,
      maximumIntervalBetweenDatesInMonths: 3.1,
      filter: {
        startDate: '',
        endDate: '',
        filter: '',
      },
      format: {
        frequencyOfUsexlsx: 'xlsx',
        frequencyOfUsecsv: 'csv',
        frequencyOfUsejson: 'json',
      }
    }
  },
  async mounted(){
    this.filter.endDate  = moment(new Date()).format('YYYY-MM-DD')
    this.filter.startDate = moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')
    await this.$store.dispatch('access/updateError', '')
    await this.handleLogsFrequencyOfUse()
  },
  methods: {
    ...mapActions('userActionLogs', {
      handleGetLogsFrequencyOfUse: 'handleGetLogsFrequencyOfUse',
      exportFrequencyOfUse: 'exportFrequencyOfUse',
      resetLogsFrequencyOfUseMeta: 'resetLogsFrequencyOfUseMeta',
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    datesIsValid() {
      return this.filter.startDate && this.filter.endDate;
    },
    dateRangeIsValid(){
      return this.datesIsValid() && moment.duration(moment(new Date(this.filter.endDate)).diff(moment(new Date(this.filter.startDate)))).asMonths() <= this.maximumIntervalBetweenDatesInMonths
    },
    selectReportFormat() {
      this.type = null;
      this.$bvModal.show('select_format')
    },
    async generateReport() {
      try {
        await this.$store.dispatch('access/updateError', '')
        if(this.dateRangeIsValid()) {
          if (moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))) {
            await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
            return
          }
          if(!this.type){
            this.$bvToast.toast('Selecione o formato do arquivo.', {
              title: 'Atenção',
              variant: 'danger',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 5000,
            });
            return
          }
          this.$bvModal.hide('select_format')
          this.loadingExport = true
          const { startDate, endDate, filter } = this.filter
          const payload = {
            user: this.user.id,
            type: this.type,
            job: 'frequencyOfUseReport',
            format: this.format[this.type],
            parameters: {
              startDate: startDate || 'not_selected',
              endDate: endDate || 'not_selected',
              filter: filter || 'not_selected'
            }
          }
          const { data, status } = await this.handleCreateReportDownloads(payload)

          let message = data.message
          let variant = 'danger'
          this.loadingExport = false
          if (status === 201) {
            message = data.message;
            variant = 'success';
            this.$refs['alert'].show()
            return
          }
          this.$bvToast.toast(message, {
            title: 'Atenção',
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
          });
          return
        }
        await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
      } catch (e) {
        this.loadingExport = false
      }
    },
    async applyFilters(){
      await this.handleLogsFrequencyOfUse(this.filter)
    },
    async resetFilters(){
      this.filter.filter = '';
      await this.resetLogsFrequencyOfUseMeta()
      await this.handleLogsFrequencyOfUse()
    },
    async handleLogsFrequencyOfUse(payload) {
      this.loading = true;
      const params = {
        ...this.logsFrequencyOfUseMeta,
        ...payload
      }
      await this.handleGetLogsFrequencyOfUse(params)
      this.loading = false;
    },

    async getFrequencyOfUseCsv() {
      const surveys = await this.exportFrequencyOfUse()
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(surveys)
      XLSX.utils.book_append_sheet(wb, ws, 'frequenciaDeUso')
      XLSX.writeFile(wb, `frequenciaDeUso.csv`)
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 260px;
    height: 48px;
  }
}
.loading-spinner{
  margin-top: 250px;
}
</style>
