<template>
  <div class='answers-survey-download'>
    <span class='question'>
      <strong>Quantas pessoas trabalham em sua empresa?</strong>
      {{ body.numberOfPeopleSelected.description }}
    </span>

    <span class='question'>
      <strong>E qual o nome da sua empresa?</strong>
      {{ body.company }}
    </span>

    <span class='question'>
      <strong>Qual software de gestão você utiliza para fazer seus laudos?</strong>
      {{ body.whatManagementSoftwareYouUse ? body.whatManagementSoftwareYouUse : '-' }}
    </span>

    <span class='question'>
      <strong>A sua idade está entre qual faixa dentre as opções a seguir?</strong>
      {{ body.age.description }}
    </span>

    <span class='question'>
      <strong>Qual é o cargo que melhor descreve a sua atuação?</strong>
      {{ body.companyPosition.description }}
    </span>

    <span class='question'>
      <strong>Qual é a sua área de atuação?</strong>
      {{ body.actingArea.description }}
    </span>

    <span class='question'>
      <strong>Qual é a sua renda mensal?</strong>
      {{ body.salaryRange.description }}
    </span>

    <span class='question'>
      <strong>Quais as principais razões para você utilizar o HO Fácil Web?</strong>
      {{body.reasonsToUse.map((x) => x.description).join(', ')}}
    </span>

    <span class='question'>
      <strong>Qual é a sua principal dificuldade hoje? E como você acha que podemos te ajudar?</strong>
      {{ body.mainDifficulty }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AnswersSatisfaction',
  props: {
    body: { type: Object, required: true }
  },
  data() {
    return {
      starRatingSatisfactionSurvey: [
        {
          text: 'Péssimo',
          note: 1
        },
        {
          text: 'Ruin',
          note: 2
        },
        {
          text: 'Regular',
          note: 3
        },
        {
          text: 'Bom',
          note: 4
        },
        {
          text: 'Ótimo',
          note: 5
        }
      ]
    }
  }
}
</script>

<style lang='scss' scoped>
.answers-survey-download {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
  }
  .question{
    margin-top: 10px
  }
}
</style>
