<template>
  <div class="surveys-table">
    <base-table
      v-if="logsFrequencyOfUse && logsFrequencyOfUseMeta"
      :columns="columns"
      :data="logsFrequencyOfUse"
      :total-items="logsFrequencyOfUseMeta.total"
      :start-items="logsFrequencyOfUseMeta.skip + 1"
      :max-items="logsFrequencyOfUseMeta.limit"
      @set-page="handleChangeLogsFrequencyOfUse"
    >
      <template v-slot:body-userId="row">
        {{ row.item.userId && row.item.userId.username ? row.item.userId.username : '-' }}
      </template>
      <template v-slot:body-date="row">
          {{ row.item.date ? formatDate(row.item.date) : '' }}
      </template>
      <template v-slot:body-action="row">
        <span> {{ row.item.action ? action(row.item.action) : '' }}</span>
      </template>

      <template v-slot:body-location="row" style='min-width: 400px'>
        <div v-if='row.item.location' class='text-justify' >
          <span v-if='row.item.location.ip'><b>IP:</b> {{row.item.location.ip}}</span><br/>
          <span v-if='row.item.location.country_name'><b>País:</b> {{row.item.location.country_name}}<br/></span>
          <span v-if='row.item.location.state_prov'><b>Estado:</b> {{row.item.location.state_prov}}<br/></span>
          <span v-if='row.item.location.city'><b>Cidade:</b> {{row.item.location.city}}<br/></span>
          <span v-if='row.item.location.zipcode'><b>Cep:</b> {{row.item.location.zipcode}}<br/></span>
          <span v-if='row.item.location.district'><b>district:</b> {{row.item.location.district}}<br/></span>
          <span v-if='row.item.location.latitude'><b>Latitude:</b> {{row.item.location.latitude}}</span>
          <span v-if='row.item.location.longitude'><b> Longitude:</b> {{row.item.location.longitude}}<br/></span>
          <span v-if='row.item.location.isp'><b>Provedor de internet:</b> {{row.item.location.isp}}</span>
        </div>
        <span v-else><b>-</b></span>
      </template>

    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import {returnDescription} from '../../utils/userActionLogs'

export default {
  name: 'LogsFrequencyOfUseTable',
  props: {
    logsFrequencyOfUse: { type: Array, require: true },
    logsFrequencyOfUseMeta: { type: Object, require: true }
  },
  data(){
    return {
      ACESSOU_PAGINA_INVENTARIO_QUIMICO: 56,
      columns: [
        { key: 'userId', value: 'Usuário' },
        { key: 'action', value: 'Ação' },
        { key: 'date', value: 'Data' },
        { key: 'location', value: 'Localização' },
      ],
    }
  },
  methods: {
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
    },
    action(action){
      return  returnDescription(action)
    },
    handleChangeLogsFrequencyOfUse(page){
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.logsFrequencyOfUseMeta.limit)
      }
      this.$emit('change-logsFrequencyOfUse', params)
    }
  },
}
</script>
<style>
.custom-tooltip > .tooltip-inner{
  max-width: 800px!important;
  width: 800px!important;
  color: white;
}
</style>
<style lang="scss" scoped>
.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
