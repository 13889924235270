<template>
  <div>
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <b-modal
        ref='alert'
        scrollable
        hide-footer
        title='Atenção'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <section class='modal-font-family'>
          <p>
            A impressão do arquivo em Excel esta sendo processada,
            clique no botão abaixo para acessar a página de downloads.
          </p>

          <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button class='btn_export_modal'>
              <span class="label">Acessar</span>
            </base-button>
          </router-link>
        </section>
      </b-modal>

      <modal-see-survey :is-open='openModal' @close='openModal = false' />

      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>

      <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.filter'
          placeholder='Pesquisar por email ou tipo'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>

        <base-button
          @click="generateReport('surveysxlsx') "
          class='btn-export'
          :disabled="exportReport.surveysxlsx || !datesIsValid()"
        >
          <section v-if='exportReport.surveysxlsx' class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label="Spinning">
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em XLSX
          </section>
        </base-button>

        <base-button
          class='ml-1 btn-export'
          @click="generateReport('surveyscsv')"
          :disabled="exportReport.surveyscsv || !datesIsValid()"
        >
          <section v-if='exportReport.surveyscsv' class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label="Spinning">
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em CSV
          </section>
        </base-button>
      </div>

      <surveys-table
        :surveys='surveys'
        :surveys-meta='surveysMeta'
        @change-surveys='handleChangeSurveys'
        @get-survey='getSurvey'
        @open-survey-modal='openModal = true'
      />

      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import SurveysTable from '@/components/Surveys/Table'
import ModalSeeSurvey from '@/components/Surveys/ModalSeeSurvey'
import { mapActions, mapGetters } from 'vuex'
import XLSX from 'xlsx'
import moment from 'moment'

export default {
  name: 'Survey',
  components: {
    SurveysTable,
    ModalSeeSurvey
  },
  computed: {
    ...mapGetters('surveys', {
      surveys: 'getSurveys',
      surveysMeta: 'getSurveysMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    exportSurveys() {
      const { startDate, endDate, filter } = this.filter
      return `${process.env.VUE_APP_BASE_URL}/surveys/exportXLSX/${startDate || 'not_selected'}/${endDate || 'not_selected'}/${filter || 'not_selected'}`
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      maximumIntervalBetweenDatesInMonths: 3.1,
      loading: false,
      filter: {
        startDate: '',
        endDate: '',
        filter: '',
      },
      openModal: false,
      exportReport: {
        surveysxlsx: false,
        surveyscsv: false,
      }
    }
  },
  async mounted(){
    this.filter.endDate  = moment(new Date()).format('YYYY-MM-DD')
    this.filter.startDate = moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')
    await this.$store.dispatch('access/updateError', '')
    await this.handleChangeSurveys()
  },
  methods: {
    ...mapActions('surveys', {
      handleGetSurveys: 'handleGetSurveys',
      handleGetSurveyByIndex: 'handleGetSurveyByIndex',
      export: 'export',
      resetSurveyMeta: 'resetSurveyMeta',
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    dateRangeIsValid(){
      return this.datesIsValid() && moment.duration(moment(new Date(this.filter.endDate)).diff(moment(new Date(this.filter.startDate)))).asMonths() <= this.maximumIntervalBetweenDatesInMonths
    },
    async generateReport(downloadType) {
      await this.$store.dispatch('access/updateError', '')
      if(this.dateRangeIsValid()) {
        if(moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))){
          await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
          return
        }
        this.exportReport[downloadType] = true
        const { startDate, endDate, filter } = this.filter
        const payload = {
          user: this.user.id,
          type: downloadType,
          job: 'surveysReport',
          format: downloadType === 'surveysxlsx' ? 'xlsx' : 'csv',
          parameters: {
            startDate: startDate || 'not_selected',
            endDate: endDate || 'not_selected',
            filter: filter || 'not_selected'
          }
        }
        const { data, status } = await this.handleCreateReportDownloads(payload)

        let message = data.message
        let variant = 'danger'
        this.exportReport[downloadType] = false
        if (status === 201) {
          message = data.message;
          variant = 'success';
          this.$refs['alert'].show()
          return
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        });
        return
      }
      await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
    },
    async applyFilters(){
      await this.handleChangeSurveys(this.filter)
    },
    async resetFilters(){
      await this.$store.dispatch('access/updateError', '')
      this.filter.filter = '';
      await this.resetSurveyMeta()
      await this.handleChangeSurveys()
    },
    async handleChangeSurveys(payload) {
      this.loading = true;
      const params = {
        ...this.surveysMeta,
        ...payload
      }
      await this.handleGetSurveys(params)
      this.loading = false;
    },
    getSurvey(index) {
      this.handleGetSurveyByIndex(index)
      this.openModal = true
    },
    async getSurveyCsv() {
      const surveys = await this.export()
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(surveys)
      XLSX.utils.book_append_sheet(wb, ws, 'pesquisas')
      XLSX.writeFile(wb, `pesquisas.csv`)
    },
    datesIsValid() {
      return this.filter.startDate && this.filter.endDate;
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    width: 50%!important;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}
.loading-spinner{
  margin-top: 250px;
}
</style>
