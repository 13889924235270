<template>
  <div class='user-table'>
    <b-overlay
      :show='loading'
      rounded
      no-center
      opacity='0.7'
      spinner-small
      spinner-variant='primary'
      class='d-inline-block w-100'
    >
      <b-modal
        ref='alert'
        scrollable
        hide-footer
        title='Atenção'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <section class='modal-font-family'>
          <p>
            A impressão do arquivo em Excel esta sendo processada,
            clique no botão abaixo para acessar a página de downloads.
          </p>

          <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button class='btn_export_modal'>
              <span class="label">Acessar</span>
            </base-button>
          </router-link>
        </section>
      </b-modal>

      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>

      <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.search'
          placeholder='Pesquisar por email'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>

        <base-button
          class='btn-export'
          @click="generateReport('utmTrachingRegisterxlsx')"
          :disabled='exportReport.utmTrachingRegisterxlsx || !datesIsValid()'
        >
          <section
            v-if='exportReport.utmTrachingRegisterxlsx'
            class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label='Spinning'>
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em XLSX
          </section>
        </base-button>

        <base-button
          class='ml-1 btn-export'
          @click="generateReport('utmTrachingRegistercsv')"
          :disabled='exportReport.utmTrachingRegistercsv || !datesIsValid()'
        >
          <section v-if='exportReport.utmTrachingRegistercsv'
                   class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label='Spinning'>
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em CSV
          </section>
        </base-button>
      </div>

      <base-table
        v-if='usersUtm && usersUtmMeta'
        :columns='columns'
        :data='usersUtm'
        :total-items='usersUtmMeta.total'
        :start-items='usersUtmMeta.skip + 1'
        :max-items='usersUtmMeta.limit'
        @set-page='pagination'
      >
        <template v-slot:body-name='row'>
          <span v-if='row.item.profile && row.item.profile.name'>
            {{ row.item.profile.name }}
          </span>
        </template>

        <template v-slot:body-createdAt='row'>
          <span v-if='row.item.createdAt'>
            {{ formatDate(row.item.createdAt) }}
          </span>
          <span v-else>
            -
          </span>
        </template>

        <template v-slot:body-name='row'>
          <span v-if='row.item.profile && row.item.profile.name'>
            {{ row.item.profile.name }}
          </span>
        </template>

        <template v-slot:body-utm_source='row'>
          <span>
            {{ row.item.utm_source ? row.item.utm_source : '-'  }}
          </span>
        </template>

        <template v-slot:body-utm_medium='row'>
          <span>
            {{ row.item.utm_medium ? row.item.utm_medium : '-'  }}
          </span>
        </template>

        <template v-slot:body-utm_campaign='row'>
          <span>
            {{ row.item.utm_campaign ? row.item.utm_campaign : '-'  }}
          </span>
        </template>

        <template v-slot:body-utm_id='row'>
          <span>
            {{ row.item.utm_id ? row.item.utm_id : '-'  }}
          </span>
        </template>

        <template v-slot:body-utm_term='row'>
          <span>
            {{ row.item.utm_term ? row.item.utm_term : '-'  }}
          </span>
        </template>

        <template v-slot:body-utm_content='row'>
          <span>
            {{ row.item.utm_content ? row.item.utm_content : '-'  }}
          </span>
        </template>
      </base-table>

      <template v-slot:overlay>
        <div class='text-center loading-spinner'>
          <b-spinner style='width: 3rem; height: 3rem;' variant='primary' label='Large Spinner'></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'UserUTMTable',
  data() {
    return {
      filter: {
        startDate: '',
        endDate: '',
        search: ''
      },
      maximumIntervalBetweenDatesInMonths: 3.1,
      loading: false,
      columns: [
        { key: 'username', value: 'Email' },
        { key: 'createdAt', value: 'Data do cadastro' },
        { key: 'utm_source', value: 'utm_source' },
        { key: 'utm_medium', value: 'utm_medium' },
        { key: 'utm_campaign', value: 'utm_campaign' },
        { key: 'utm_id', value: 'utm_id' },
        { key: 'utm_term', value: 'utm_term' },
        { key: 'utm_content', value: 'utm_content' },
      ],
      exportReport: {
        utmTrachingRegisterxlsx: false,
        utmTrachingRegistercsv: false
      }
    }
  },
  computed: {
    ...mapGetters('user', {
      usersUtm: 'usersUtm',
      usersUtmMeta: 'usersUtmMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('user', {
      handleGetUsersUtm: 'handleGetUsersUtm',
      resetUsersUtmMeta: 'resetUsersUtmMeta',
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy: HH:mm:ss')
    },

    async applyFilters() {
      await this.listAllUsers(this.filter)
    },

    async resetFilters() {
      this.filter.search = ''
      await this.resetUsersUtmMeta()
      await this.listAllUsers({})
    },

    async pagination(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.usersUtmMeta.limit)
      }
      await this.listAllUsers(params)
    },

    async listAllUsers(payload) {
      try {
        this.loading = true
        const params = {
          ...this.usersUtmMeta,
          ...payload
        }

        await this.handleGetUsersUtm(params)
        this.loading = false
      } catch (e) {

      }
    },

    datesIsValid() {
      return this.filter.startDate && this.filter.endDate;
    },

    dateRangeIsValid(){
      return this.datesIsValid() && moment.duration(moment(new Date(this.filter.endDate)).diff(moment(new Date(this.filter.startDate)))).asMonths() <= this.maximumIntervalBetweenDatesInMonths
    },

    async generateReport(downloadType) {
      await this.$store.dispatch('access/updateError', '')
      if(this.dateRangeIsValid()) {
        if(moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))){
          await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
          return
        }
        this.exportReport[downloadType] = true
        const { startDate, endDate, search } = this.filter
        const payload = {
          user: this.user.id,
          type: downloadType,
          job: 'utmTraching',
          format: downloadType === 'utmTrachingRegisterxlsx' ? 'xlsx' : 'csv',
          parameters: {
            startDate: startDate || null,
            endDate: endDate || null,
            search: search || null
          }
        }
        const { data, status } = await this.handleCreateReportDownloads(payload)

        let message = data.message
        let variant = 'danger'
        this.exportReport[downloadType] = false
        if (status === 201) {
          message = data.message
          variant = 'success'
          this.$refs['alert'].show()
          return
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        return
      }
      await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
    },
  },
  async mounted() {
    this.filter.endDate  = moment(new Date()).format('YYYY-MM-DD')
    this.filter.startDate = moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')
    await this.$store.dispatch('access/updateError', '')
    await this.listAllUsers({})
  },
  async created() {
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}

.loading-spinner {
  margin-top: 250px;
}
</style>
