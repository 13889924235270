<template>
  <div class="answers-survey-download">
    <span class="question">
      <strong>Em uma escala de 1 a 5, como você avalia a nossa plataforma?</strong>
      {{starRatingSatisfactionSurvey.find((x) => x.note === body.satisfaction).text}}
    </span>

    <span class="question">
      <strong>Me conte o porquê você me deu essa nota?</strong>
      <span v-if='body && body.justification'>{{body.justification}}</span>
      <span v-else>-</span>
    </span>
  </div>
</template>

<script>
export default {
  name: "AnswersSatisfaction",
  props: {
    body: { type: Object, required: true }
  },
  data(){
    return {
      starRatingSatisfactionSurvey: [
        {
          text: 'Péssimo',
          note: 1,
        },
        {
          text: 'Ruim',
          note: 2,
        },
        {
          text: 'Regular',
          note: 3,
        },
        {
          text: 'Bom',
          note: 4,
        },
        {
          text: 'Ótimo',
          note: 5,
        }
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.answers-survey-download {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    flex-direction: column;
  }
}
</style>
