<template>
  <div class="surveys-table">
    <base-table
      v-if="chemicalAgentsResearchedMeta && chemicalAgentsResearchedMeta"
      :columns="columns"
      :data="chemicalAgentsResearched"
      :total-items="chemicalAgentsResearchedMeta.total"
      :start-items="chemicalAgentsResearchedMeta.skip + 1"
      :max-items="chemicalAgentsResearchedMeta.limit"
      @set-page="handleChangeChemicalAgentsResearched"
    >
      <template v-slot:body-userId="row">
        {{ row.item.userId.username ? row.item.userId.username : '' }}
      </template>
      <template v-slot:body-action="row">
          {{ row.item.action ? action(row.item.action) : '' }}
      </template>

    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import {returnDescription} from '../../utils/userActionLogs'

export default {
  name: 'ChemicalAgentsResearchedTable',
  props: {
    chemicalAgentsResearched: { type: Array, require: true },
    chemicalAgentsResearchedMeta: { type: Object, require: true }
  },
  data(){
    return {
      columns: [
        { key: 'username', value: 'Usuário' },
        { key: 'cas', value: 'Cas' },
        { key: 'count', value: 'Quantidade' },
        { key: 'date', value: 'Data' },
      ],
    }
  },
  methods: {
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
    },
    action(action){
      return  returnDescription(action)
    },
    handleChangeChemicalAgentsResearched(page){
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.chemicalAgentsResearchedMeta.limit)
      }
      this.$emit('change-chemicalAgentsResearched', params)
    }
  },
}
</script>

<style lang="scss" scoped>
.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;
  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
