<template>
  <div class='surveys-table'>
    <base-table
      v-if='searchAgents && searchAgentsMeta'
      :columns='columns'
      :data='searchAgents'
      :total-items='searchAgentsMeta.total'
      :start-items='searchAgentsMeta.skip + 1'
      :max-items='searchAgentsMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-user='row'>
        {{row.item.user &&  row.item.user.username ? row.item.user.username : '-' }}
      </template>

      <template v-slot:body-createdAt='row'>
        {{ formatDate(row.item.createdAt) }}
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'

export default {
  name: 'SearchAgentsTable',
  props: {
    searchAgents: { type: Array, require: true },
    searchAgentsMeta: { type: Object, require: true }
  },
  async created() {
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'user', value: 'Usuário' },
        { key: 'researched', value: 'Pesquisado' },
        { key: 'createdAt', value: 'Data' },
      ]
    }
  },
  components: {
  },
  methods: {
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },

    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.searchAgentsMeta.limit)
      }
      this.$emit('change-searchAgents', params)
    }
  }
}
</script>

<style>
.tooltip-inner {
  background: #FFFFFF;
  text-align: left;
  max-width: 600px !important;
  width: 600px !important;
}
</style>
<style lang='scss' scoped>

.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.whatTheSearchReturned {
  cursor: pointer;
  text-decoration: underline;
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
