<template>
  <div>
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <b-modal
        ref='alert'
        scrollable
        hide-footer
        title='Atenção'
        header-bg-variant='info'
        header-text-variant='light'
      >
        <section class='modal-font-family'>
          <p>
            A impressão do arquivo em Excel esta sendo processada,
            clique no botão abaixo para acessar a página de downloads.
          </p>

          <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button class='btn_export_modal'>
              <span class="label">Acessar</span>
            </base-button>
          </router-link>
        </section>
      </b-modal>

      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>

      <div class='inputs d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model='filter.filter'
          placeholder='Pesquisar por email'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>

          <base-button
            class='btn-export'
            @click="generateReport('chemicalAgentsResearchedxlsx')"
            :disabled="exportReport.chemicalAgentsResearchedxlsx || !datesIsValid()"
          >
            <section v-if='exportReport.chemicalAgentsResearchedxlsx' class='d-flex align-items-center justify-content-center'>
              <b-spinner
                class='loading-button'
                color='#FFFFFF'
                label="Spinning">
              </b-spinner>
              <span class='ml-2'>Exportando...</span>
            </section>

            <section v-else>
              <i class='fas fa-download' />
              Exportar em XLSX
            </section>
          </base-button>

        <base-button
          class='ml-1 btn-export'
          @click="generateReport('chemicalAgentsResearchedcsv')"
          :disabled="exportReport.chemicalAgentsResearchedcsv || !datesIsValid()"
        >
          <section v-if='exportReport.chemicalAgentsResearchedcsv' class='d-flex align-items-center justify-content-center'>
            <b-spinner
              class='loading-button'
              color='#FFFFFF'
              label="Spinning">
            </b-spinner>
            <span class='ml-2'>Exportando...</span>
          </section>

          <section v-else>
            <i class='fas fa-download' />
            Exportar em CSV
          </section>
        </base-button>
      </div>

      <chemical-agents-researched-table
        :chemicalAgentsResearched='chemicalAgentsResearched'
        :chemicalAgentsResearchedMeta='chemicalAgentsResearchedMeta'
        @change-chemicalAgentsResearched='listAllChemicalAgentsResearched'
      />

      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ChemicalAgentsResearchedTable from '@/components/ChemicalAgentsResearched/Table'
import { mapActions, mapGetters } from 'vuex'
import XLSX from 'xlsx'
import moment from 'moment'
export default {
  name: 'ChemicalAgentsResearched',
  components: {
    ChemicalAgentsResearchedTable
  },
  computed: {
    ...mapGetters('chemicalAgentsResearched', {
      chemicalAgentsResearched: 'getChemicalAgentsResearched',
      chemicalAgentsResearchedMeta: 'getChemicalAgentsResearchedMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    exportChemicalAgentsResearchedXLSX() {
      const { startDate, endDate, filter } = this.filter
      return `${process.env.VUE_APP_BASE_URL}/chemical-agents-researched/exportXLSX/${startDate || 'not_selected'}/${endDate || 'not_selected'}/${filter || 'not_selected'}`
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      maximumIntervalBetweenDatesInMonths: 3.1,
      loading: false,
      filter: {
        startDate: '',
        endDate: '',
        filter: '',
      },
      exportReport: {
        chemicalAgentsResearchedxlsx: false,
        chemicalAgentsResearchedcsv: false,
      }
    }
  },
  async mounted(){
    this.filter.endDate  = moment(new Date()).format('YYYY-MM-DD')
    this.filter.startDate = moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')
    await this.$store.dispatch('access/updateError', '')
    await this.listAllChemicalAgentsResearched()
  },
  methods: {
    ...mapActions('chemicalAgentsResearched', {
      handleGetChemicalAgentsResearched: 'handleGetChemicalAgentsResearched',
      resetLogsChemicalAgentsResearchedMeta: 'resetLogsChemicalAgentsResearchedMeta',
      exportChemicalAgentsResearched: 'exportChemicalAgentsResearched',
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    datesIsValid() {
      return this.filter.startDate && this.filter.endDate;
    },
    dateRangeIsValid(){
      return this.datesIsValid() && moment.duration(moment(new Date(this.filter.endDate)).diff(moment(new Date(this.filter.startDate)))).asMonths() <= this.maximumIntervalBetweenDatesInMonths
    },
    async generateReport(downloadType) {
      await this.$store.dispatch('access/updateError', '')
      if(this.dateRangeIsValid()) {
        if (moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))) {
          await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
          return
        }
        this.exportReport[downloadType] = true;
        const { startDate, endDate, filter } = this.filter
        const payload = {
          user: this.user.id,
          type: downloadType,
          job: 'chemicalAgentsResearchedReport',
          format: downloadType === 'chemicalAgentsResearchedxlsx' ? 'xlsx' : 'csv',
          parameters: {
            startDate: startDate || 'not_selected',
            endDate: endDate || 'not_selected',
            filter: filter || 'not_selected'
          }
        }
        const { data, status } = await this.handleCreateReportDownloads(payload)

        let message = data.message
        let variant = 'danger'
        this.exportReport[downloadType] = false;
        if (status === 201) {
          message = data.message;
          variant = 'success';
          this.$refs['alert'].show()
          return
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        });
        return
      }
      await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
    },
    async applyFilters(){
      await this.listAllChemicalAgentsResearched(this.filter)
    },
    async resetFilters(){
      this.filter.filter = '';
      await this.resetLogsChemicalAgentsResearchedMeta()
      await this.listAllChemicalAgentsResearched()
    },
    async listAllChemicalAgentsResearched(payload) {
      this.loading = true;
      const params = {
        ...this.chemicalAgentsResearchedMeta,
        ...payload
      }
      await this.handleGetChemicalAgentsResearched(params)
      this.loading = false;
    },

    async getChemicalAgentsResearched() {
      const surveys = await this.exportChemicalAgentsResearched()
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(surveys)
      XLSX.utils.book_append_sheet(wb, ws, 'agentesAdicionados')
      XLSX.writeFile(wb, `agentesAdicionados.csv`)
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}
.loading-spinner{
  margin-top: 250px;
}
</style>
