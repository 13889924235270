<template>
  <div class='user-table'>
    <b-overlay
      :show='loading'
      rounded
      no-center
      opacity='0.7'
      spinner-small
      spinner-variant='primary'
      class='d-inline-block w-100'
    >
      <div class='inputs w-100 d-flex align-items-end justify-content-between mb-2'>
        <base-input-text
          block
          class=' ml-1 mt-1'
          v-model='user'
          placeholder='Pesquisar por email do usuário'
          type='text'
        />

        <base-input-text
          block
          class=' ml-1 mt-1'
          v-model='company'
          placeholder='Pesquisar por nome da empresa'
          type='text'
        />

        <base-input-text
          class=' ml-1'
          block
          v-model='product'
          placeholder='Pesquisar por nome do produto'
          type='text'
        />

        <base-button
          class='btn-pesquisar ml-1 mt-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros mt-1'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>
      </div>

      <base-table
        v-if='attachmentProducts && attachmentProductsMeta'
        :columns='columns'
        :data='attachmentProducts'
        :total-items='attachmentProductsMeta.total'
        :start-items='attachmentProductsMeta.skip + 1'
        :max-items='attachmentProductsMeta.limit'
        @set-page='pagination'
      >
        <template v-slot:body-username='row'>
          {{ row.item.username ? row.item.username : '-' }}
        </template>


        <template v-slot:body-urlFISPQ='row'>
          <a v-if='row.item.urlFISPQ' :href='row.item.urlFISPQ' target='_blank'>
            <strong>Clique aqui para baixar</strong>
          </a>
        </template>

      </base-table>

      <template v-slot:overlay>
        <div class='text-center loading-spinner'>
          <b-spinner style='width: 3rem; height: 3rem;' variant='primary' label='Large Spinner'></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment/moment'

export default {
  name: 'AttachmentTable',
  data() {
    return {
      company: null,
      product: null,
      user: null,
      maximumIntervalBetweenDatesInMonths: 3.1,
      loading: false,
      columns: [
        { key: 'username', value: 'Usuário' },
        { key: 'company', value: 'Empresa' },
        { key: 'product', value: 'Produto' },
        { key: 'urlFISPQ', value: 'Link da FDS/FISPQ' }
      ],
    }
  },
  computed: {
    ...mapGetters('productLegalConsultation', {
      attachmentProductsMeta: 'attachmentProductsMeta',
      attachmentProducts: 'attachmentProducts'
    }),
    errors() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('productLegalConsultation', {
      handleProductLegalConsultationAttachments: 'handleProductLegalConsultationAttachments',
      resetFilterProductLegalConsultationAttachments: 'resetFilterProductLegalConsultationAttachments',
    }),
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy: HH:mm:ss')
    },

    async applyFilters() {
      await this.listAttachments({
        company: this.company,
        product: this.product,
        user: this.user,
      })
    },

    async resetFilters() {
      this.company = null
      this.product = null
      this.user = null
      await this.resetFilterProductLegalConsultationAttachments()
      await this.listAttachments({
        company: this.company,
        product: this.product,
        user: this.user,
      })
    },

    async pagination(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.attachmentProductsMeta.limit),
        company: this.company,
        product: this.product,
        user: this.user,
      }
      await this.listAttachments(params)
    },

    async listAttachments(payload) {
      try {
        this.loading = true
        const params = {
          ...this.attachmentProductsMeta,
          ...payload
        }

        await this.handleProductLegalConsultationAttachments(params)
        this.loading = false
      } catch (e) {

      }
    }
  },
  async mounted() {
    await this.$store.dispatch('access/updateError', '')
    await this.resetFilters()
    await this.listAttachments(
      {
        company: this.company,
        product: this.product,
        user: this.user,
      }
    )
  },
  async created() {
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    height: 48px;
    min-width: 105px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}

.loading-spinner {
  margin-top: 250px;
}
</style>
